<template>
  <div id="alert-list">
    <!-- dialog -->

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchAlerts"
    ></delete-confirmation>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="alert-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedAlert"
        :headers="alertColumns"
        :items="alertList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isAlertFormActive = !isAlertFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isAlertFormActive = !isAlertFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="selectItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>Disable</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import demographicsService from '@/services/DemographicsService'
import useDemographicsService from '@/store/useDemographicsService'

export default {
  components: { DeleteConfirmation },
  setup() {
    const DEMOGRAPHICS_APP = 'app-demographics'

    // Register module
    if (!store.hasModule(DEMOGRAPHICS_APP)) {
      store.registerModule(DEMOGRAPHICS_APP, demographicsService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEMOGRAPHICS_APP)) {
        store.unregisterModule(DEMOGRAPHICS_APP)
      }
    })

    const {
      alertList,
      alertColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAlert,
      readCode,

      fetchAlerts,
      deleteAlert,
    } = useDemographicsService()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isAlertFormActive = ref(false)

    return {
      alertList,
      alertColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAlert,
      isAlertFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      readCode,

      deleteAlert,
      fetchAlerts,
      icons: {
        mdiAccountOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchAlerts()

    this.$watch('alertList', () => {
      console.log('alertList', this.alertList)
    })

  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    submitDelete(alertDTO) {
      this.deleteAlert(alertDTO)
    },
  },
}
</script>
